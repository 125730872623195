import React, { FunctionComponent } from 'react'
import ReactEcharts from 'echarts-for-react'
import { setOptions } from './chartUtils'
import { EChartOption } from 'echarts'
import { ChartConfig } from '../../types/types'

const BarChart: FunctionComponent<{ data: ChartConfig }> = ({ data }) => {
  const option: EChartOption = {
    title: {
      text: `${data.xAxis} vs ${data.yAxis}`,
      subtext: `Aggregated by - ${data.agg}`,
    },
    dataset: {
      source: data.dataset.toArray(),
    },
    xAxis: { type: 'category' },
    yAxis: { name: data.xAxis },
    tooltip: {
      trigger: 'axis',
    },
    series: [
      {
        type: 'bar',
        encode: {
          x: data.xAxis,
          y: data.yAxis,
        },
      },
    ],
  }

  return (
    <div>
      <ReactEcharts option={option} />
    </div>
  )
}

export default BarChart

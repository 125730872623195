import React from 'react'
import styled from 'styled-components'
import { useDfContext } from '../../../context/dataframe'

const ColumnContainer = styled.div`
  span {
    float: right;
    margin-right: 0.5rem;
    padding: 0.2rem 0.4rem;
    cursor: pointer;

    &:hover {
      transform: scale(1.1);
    }
  }
  ul {
    list-style-type: none;
    margin: 0;
    h3 {
      text-transform: underline;
    }
    li {
      text-align: left;
      margin: 0.5rem;
      code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
        background-color: rgba(0, 0, 0, 0.04);
        border-radius: 3px;
        padding: 0.2rem 0;
      }
    }
  }
`

const ColumnInfoView = () => {
  const serie = useDfContext().workingColumn?.serie
  const col = useDfContext().workingColumn?.column

  if (!col || !serie) {
    return null
  }
  return (
    <ColumnContainer>
      <h3 className='m-0 text-center mt-1 font-mono underline pb-1'>{col.Column}</h3>
      <ul className='m-0 px-2 text-xs'>
        {col.Type === 'number' && (
          <>
            <li>
              Type:
              <code>{col.Type}</code>
            </li>
            <li>
              Average <code>{serie.average()}</code>
            </li>
            <li>
              Median <code>{serie.median()}</code>
            </li>
            <li>
              Min <code>{serie.min()}</code>
            </li>
            <li>
              Max <code>{serie.max()}</code>
            </li>
            <li>
              Std Deviation <code>{serie.std()}</code>
            </li>
            <li>
              Count <code>{serie.count()}</code>
            </li>
            <li>
              Unique <code>{serie.distinct().count()}</code>
            </li>
          </>
        )}
        {col.Type === 'boolean' && (
          <>
            <li>
              Type: <code>{col.Type}</code>
            </li>
            <li>
              Count <code>{serie.count()}</code>
            </li>
            <li>
              Unique <code>{serie.distinct().count()}</code>
            </li>
            <li>
              Most Frequent{' '}
              <code>
                {
                  serie
                    .distinct()
                    .detectValues()
                    .orderByDescending((val) => val.Frequency)
                    .first().Value
                }
              </code>
            </li>
            <li>
              Frequency{' '}
              <code>
                {serie
                  .distinct()
                  .detectValues()
                  .orderByDescending((val) => val.Frequency)
                  .first()
                  .Frequency.toFixed(2)}
                %
              </code>
            </li>
          </>
        )}
        {col.Type === 'string' && (
          <>
            <li>
              Type: <code>{col.Type}</code>
            </li>
            <li>
              Count <code>{serie.count()}</code>
            </li>
            <li>
              Unique <code>{serie.distinct().count()}</code>
            </li>
            <li>
              Most Frequent{' '}
              <code>
                {
                  serie
                    .distinct()
                    .detectValues()
                    .orderByDescending((val) => val.Frequency)
                    .first().Value
                }
              </code>
            </li>
            <li>
              Frequency{' '}
              <code>
                {serie
                  .distinct()
                  .detectValues()
                  .orderByDescending((val) => val.Frequency)
                  .first()
                  .Frequency.toFixed(2)}
                %
              </code>
            </li>
          </>
        )}
      </ul>
    </ColumnContainer>
  )
}

export default ColumnInfoView

import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'
import { DataFrame, ISeries } from 'data-forge'
import { ChartConfig, ChartData, Options } from '../types/types'
import { ITypeFrequency } from 'data-forge/build/lib/dataframe'
import { prepareOptions } from '../utils/transformer'

type DataframeContext = {
  dataframe: DataFrame | undefined
  setDataframe: Dispatch<SetStateAction<DataFrame<number, any> | undefined>>
  mode: 'table' | 'column' | 'edit' | 'chart'
  setMode: Dispatch<SetStateAction<'table' | 'column' | 'edit' | 'chart'>>
  workingColumn: { column: ITypeFrequency; serie: ISeries } | undefined
  setWorkingColumn: (name: ITypeFrequency | null) => void
  handleChart: (options: Options) => Promise<boolean>
  chartData: ChartConfig | undefined
}

const DataframeContext = React.createContext<DataframeContext>({} as DataframeContext)
const useDfContext = () => useContext(DataframeContext)

//@ts-ignore
const DataframeProvider = (props) => {
  const [dataframe, setDataframe] = useState<DataFrame>()
  const [chartData, setChartData] = useState<ChartConfig>()
  const [mode, setMode] = useState<'table' | 'column' | 'edit' | 'chart'>('table')

  // Set the column/serie the user is currenly working on
  const [workingColumn, updateWorkingColumn] = useState<{ column: ITypeFrequency; serie: ISeries }>()

  const setWorkingColumn = (col: ITypeFrequency | null) => {
    if (col === null) {
      updateWorkingColumn(undefined)
      return
    }
    const serie = dataframe?.getSeries(col.Column)
    console.log('active Serie', serie)

    if (serie) {
      updateWorkingColumn({ column: col, serie })
    }
  }

  const handleChart = async (options: Options) => {
    console.log('should work', options, dataframe)
    try {
      if (!dataframe) {
        console.error('no dataframe')
        return false
      }

      const chartDF = await prepareOptions(options, dataframe)
      const chartConfig: ChartConfig = {
        dataset: chartDF,
        xAxis: options.xAxis,
        yAxis: options.yAxis,
        agg: options.aggregate,
      }
      await setChartData(chartConfig)
    } catch (e) {
      console.error('errir', e)
      return Promise.reject(false)
    } finally {
      return Promise.resolve(true)
    }
  }

  // Do stuff if we have existing file urls
  useEffect(() => {})
  return (
    <DataframeContext.Provider
      value={{ dataframe, setDataframe, mode, setMode, workingColumn, setWorkingColumn, handleChart, chartData }}
    >
      {props.children}
    </DataframeContext.Provider>
  )
}

export { DataframeProvider, useDfContext }

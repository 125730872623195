import React, { useState } from 'react'
import DataTable from 'react-data-table-component'
import styled from 'styled-components'
import { useDfContext } from '../../../context/dataframe'

const TableContainer = styled(DataTable)`
  // Disable header for now since we have no content there
  .rdt_TableHeader {
    min-height: 0;
  }
  .rdt_TableCol {
    font-size: 1rem;
  }
  .rdt_TableCell {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }
`

const Table = () => {
  const DFContext = useDfContext()
  const dataframe = DFContext.dataframe!
  // Only get the 100 first rows
  // useMemo and https://github.com/bvaughn/react-window to make it more effiecient later
  const [data, setData] = useState(dataframe.endAt(99).toArray())
  const columns = Object.keys(data[0]).map((item) => {
    return { name: item, selector: item }
  })

  return <TableContainer fixedHeader={true} columns={columns} data={data} dense={true} />
}

export default Table

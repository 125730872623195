import React, { useState } from 'react'
import Form from '../components/generic/form'
import firebase from '../firebase'

const Feedback = () => {
  const [issue, updateIssue] = useState('')

  return (
    <Form>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          const user = firebase.auth.currentUser?.email
          fetch('https://getform.io/f/a21fd6eb-a8be-4219-b236-cff9c29c2972', {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify({ user, contet: issue }),
            headers: {
              'Content-Type': 'application/json',
            },
          })
            .then(() => {
              alert('Thank you for your feedback')
            })
            .catch((err) => {
              alert(`An error occured ${err}`)
            })
        }}
      >
        <h2>Feeback</h2>
        <div>
          <label>For any questions, issues or inqueries send us a message.</label>
          <textarea name='content' value={issue} onChange={(e) => updateIssue(e.target.value)}></textarea>
        </div>
        <input type='submit' value='Submit' />
        <p>
          You can also contact us directly at: <code>contact@explot.io</code>
        </p>
      </form>
    </Form>
  )
}

export default Feedback

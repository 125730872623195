import React, { useState, FunctionComponent, FC } from 'react'
import styled from 'styled-components'
import { ChartMultitype32, TableSplit32 } from '@carbon/icons-react'
import DataTableView from './dataTableView'
import Loading from '../../loading'
import { useDfContext } from '../../../context/dataframe'
import SelectCellType from './selectCellType'

const DataContainer = styled.div<{ width?: string }>`
  //width: ${(props) => (props.width ? props.width : '50%')};
  flex-grow: 1;
  max-width: ${(props) => (props.width ? props.width : '50%')};
`

const ChartOptionsNav = styled.ul`
  background-color: #b9f4bc;
  height: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`
const ChartOptionsLi = styled.li<{ active: boolean }>`
  span {
    outline: ${(props) => (props.active ? '1px solid #24b47e' : 0)};
    display: flex;
    flex-direction: column;
    margin: auto;
    cursor: pointer;
    padding: 0.6rem;
    &:hover {
      -moz-box-shadow: inset 0 0 0.6rem #24b47e;
      -webkit-box-shadow: inset 0 0 0.6rem #24b47e;
      box-shadow: inset 0 0 0.6rem #24b47e;
    }
    svg {
      margin: auto;
    }
  }
`

const DataViewContainer: FC<any> = ({ toggleTable, table }) => {
  const dataframe = useDfContext().dataframe
  const hasChartData = !!useDfContext().chartData

  // Load in the dataframe once a file has been chosen
  if (!dataframe) {
    return (
      <DataContainer width='90%'>
        <SelectCellType />
      </DataContainer>
    )
  }

  if (dataframe && !hasChartData) {
    return (
      <DataContainer width='70%'>
        <DataTableView />
      </DataContainer>
    )
  }

  if (dataframe && hasChartData) {
    return (
      <DataContainer width='10%'>
        <ChartOptionsNav>
          <ChartOptionsLi onClick={() => toggleTable(false)} title='View Chart' active={!table}>
            <span>
              Chart
              <ChartMultitype32 />
            </span>
          </ChartOptionsLi>
          <ChartOptionsLi onClick={() => toggleTable(true)} title='View Table' active={table}>
            <span>
              Table
              <TableSplit32 />
            </span>
          </ChartOptionsLi>
        </ChartOptionsNav>
      </DataContainer>
    )
  }
  // Render this view when we have a `chartData`-ish object
  if (false) {
    return (
      <DataContainer width='10%'>
        <h2>Chart Mode</h2>
      </DataContainer>
    )
  }
  return <Loading />
}

export default DataViewContainer

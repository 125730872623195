import React from "react"

const Loading = () => {
  console.log("loaded")
  return (
    <div className="">
      <h3>Processing</h3>
    </div>
  )
}

export default Loading

import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'

// Keep track of all the files in a `worksheet`

type FileContext = {
  savedFiles: File[] | undefined
  addFile: (file: File) => void
}

const FileContext = React.createContext<FileContext>({} as FileContext)
const useFileContext = () => useContext(FileContext)

//@ts-ignore

const FileProvider = (props) => {
  const [savedFiles, updateFiles] = useState<Array<File>>()
  const addFile = (file: File) => {
    if (!savedFiles) {
      updateFiles([file])
    } else {
      updateFiles((prev) => {
        return [...prev!, file]
      })
    }
  }
  return <FileContext.Provider value={{ savedFiles, addFile }}>{props.children}</FileContext.Provider>
}

export { FileProvider, useFileContext }

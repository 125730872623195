import React, { useState, FunctionComponent } from 'react'
import BarChart from '../../charts/barChart'
import LineChart from '../../charts/lineChart'
import ScatterChart from '../../charts/scatterChart'
import PieChart from '../../charts/pieChart'
import { InformationFilled32 } from '@carbon/icons-react'
import { ChartType } from '../../../types/types'
import mixpanel from 'mixpanel-browser'
import { useDfContext } from '../../../context/dataframe'
import styled from 'styled-components'

const ChartContainer = styled.div`
  padding: 0.8rem 0.2rem;
`

/**
 * Renders the chart based on the @type
 */
const ChartGraphView: FunctionComponent<{
  chart: ChartType
}> = ({ chart }) => {
  const [moreInfo, toggleMoreInfo] = useState(false)
  mixpanel.track('rendered_chart')
  const chartData = useDfContext().chartData!
  const renderChart = (chartType: string) => {
    if (chartType === 'bar') {
      return <BarChart data={chartData} />
    }
    if (chartType === 'line') {
      return <LineChart data={chartData} />
    }
    if (chartType === 'scatter') {
      return <ScatterChart data={chartData} />
    }
    if (chartType === 'pie') {
      return <PieChart data={chartData} />
    }
    return <h2>Error</h2>
  }
  return <ChartContainer>{renderChart(chart.type)}</ChartContainer>
}

export default ChartGraphView

import React, { useState, FunctionComponent, FC } from 'react'
import styled from 'styled-components'
import DataViewContainer from './data-view/dataViewContainer'
import ChartContainer from './chart-view/chartContainer'
import { ChartConfig } from '../../types/types'
import { DataframeProvider, useDfContext } from '../../context/dataframe'
import { CellViewProvider } from '../../context/view'

const CellContainerStyle = styled.div`
  width: 85vw;
  min-height: 264px;
  height: 16rem;
  overflow: hidden;
  border: 2px solid #e2e8f0;
  margin: auto;
  margin-top: 1em;
  margin-bottom: 1em;
  display: flex;
  justify-content: center;
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgb(199, 200, 201);
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
`

const CellStyle = styled.div<any>`
  height: 100%;
  background-color: ${(props) => props.bg || '#b9f4bc'};
  width: 50%;
`

/**
 *
 * THOUGHTS: Turn this into a `Class` components instead to better handle the constructor?
 * That way when we add a new cell we can call it with new Cell()
 */
const CellContainer: FC = () => {
  const [chartConfig, setChartConfig] = useState<ChartConfig | null>(null)
  const [table, toggleTable] = useState(false)

  return (
    <DataframeProvider>
      <CellContainerStyle className='cell-container bg-light-green md:flex my-4 mx-auto overflow-hidden flex-column'>
        <DataViewContainer toggleTable={toggleTable} table={table} />
        <ChartContainer setChartConfig={setChartConfig} table={table} />
      </CellContainerStyle>
    </DataframeProvider>
  )
}

export default CellContainer

import React, { Dispatch, FunctionComponent, SetStateAction, useEffect, useState, FC } from 'react'
import * as df from 'data-forge'
import styled from 'styled-components'
import { ITypeFrequency } from 'data-forge/build/lib/dataframe'
import { useDfContext } from '../../../context/dataframe'
import Table from './table'

const TableNav = styled.nav<any>`
  width: 100%;
  min-height: 32px;
  max-height: 10rem;
  display: flex;
  justify-content: center;
  padding: 0.25rem 0;
`
const TableSpan = styled.span<any>`
  margin: 0 1rem;
  border-bottom: ${(props) => (props.active ? `4px solid #b9f4bc;` : 'none')};
  cursor: pointer;
  &:hover {
    background-color: #b9f4bc;
  }
`

const ColumnTable = styled.table`
  margin: auto;
  width: 80%;
  overflow: scroll;
  tr {
    border-bottom: 1px solid black;
    cursor: pointer;
    &:hover {
      background-color: #f3fef3;
    }
  }
  td {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }
`

const DataFrameInfoContainer: FC<{
  df: df.DataFrame
}> = ({ df }) => {
  const setActiveColumn = useDfContext().setWorkingColumn
  const types = df.detectTypes().toJSON()
  const columnData: ITypeFrequency[] = JSON.parse(types)
  return (
    <>
      <ColumnTable>
        <tr>
          <th>Column Name</th>
          <th>Type</th>
          <th>Frequency</th>
        </tr>
        <tbody>
          {columnData.map((column) => (
            <tr
              className='hover:bg-gray-100 cursor-pointer focus:bg-gray-400 text-xs '
              key={column.Column}
              onClick={() => {
                const serie = df.getSeries(column.Column)
                setActiveColumn(column)
              }}
            >
              <td>{column.Column}</td>
              <td>{column.Type}</td>
              <td>{column.Frequency}</td>
            </tr>
          ))}
        </tbody>
      </ColumnTable>
    </>
  )
}

const DataTableView: FunctionComponent = () => {
  const DFContext = useDfContext()
  const dataframe = DFContext.dataframe!
  // Only get the 100 first rows
  // useMemo and https://github.com/bvaughn/react-window to make it more effiecient later
  const [data, setData] = useState(dataframe.endAt(99).toArray())
  const [view, toggle] = useState(0)
  const [dataTypes, setDataTypes] = useState(dataframe.detectTypes().toArray())
  useEffect(() => {
    // Use this to split it into chunks of 100
    //const dfSplit = dataframe.window(99)
    //setData(jsonData)
  }, [])
  if (!data) {
    return <h1>No Data</h1>
  }
  const columns = Object.keys(data[0]).map((item) => {
    return { name: item, selector: item }
  })
  return (
    <>
      <TableNav className='w-full flex px-4 py-1 justify-center'>
        <TableSpan
          active={view === 0 && true}
          onClick={() => {
            // remove column when changin to table view
            DFContext.setMode('table')
            DFContext.setWorkingColumn(null)
            toggle(0)
          }}
          className='mx-4 hover:bg-gray-200 hover:border-b-4 cursor-not-allowed  hover:border-light-green px-2'
        >
          Table
        </TableSpan>
        <TableSpan
          active={view === 1 && true}
          onClick={() => {
            toggle(1)
            DFContext.setMode('column')
          }}
          className='mx-4 border-b-4 border-light-green cursor-pointer'
        >
          Column Info
        </TableSpan>
      </TableNav>
      {view === 1 ? <DataFrameInfoContainer df={dataframe} /> : <Table />}
    </>
  )
}

export default DataTableView

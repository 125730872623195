import React, { useState } from 'react'
import { Categories32, DocumentAdd32, TextColor32, DocumentExport32 } from '@carbon/icons-react'
import DataAddFileView from './dataAddFileView'
import styled from 'styled-components'
import { useFileContext } from '../../../context/files'
import readAndUploadFile from '../../../utils/filereader'
import { useDfContext } from '../../../context/dataframe'

const TypeContainer = styled.ul`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  list-style-type: none;
  margin: 0;
  padding: 0;
  li {
    padding: 0.5rem 0.8rem;
    cursor: pointer;
    svg {
      display: block;
      margin: auto;
      height: 4rem;
      width: 4rem;
    }

    &:hover {
      outline: 2px solid #e2e8f0;
    }
  }
`

const SelectCellType = () => {
  const allSavedFiles = useFileContext().savedFiles
  const setDataframe = useDfContext().setDataframe

  /**
   * 0 Library
   * 1 Add File
   * 2 Text Mode
   */
  const [cellType, setCellType] = useState<number | undefined>()

  if (allSavedFiles && !cellType) {
    return (
      <div>
        <h3>Use Previous File?</h3>
        <TypeContainer>
          <li
            onClick={() => {
              readAndUploadFile(allSavedFiles.slice(-1)[0])
                .then((res) => {
                  setDataframe(res)
                })
                .catch((e) => alert(`Error selecting file ${e}`))
            }}
          >
            <DocumentExport32 />
            {allSavedFiles.slice(-1)[0].name}
          </li>
          <li onClick={() => setCellType(1)}>
            <DocumentAdd32 /> New File
          </li>
        </TypeContainer>
      </div>
    )
  }

  if (!cellType) {
    return (
      <div>
        <h2>Let's get started</h2>
        <p>Click on the file icon below to upload a file and start exploring.</p>
        {/*<p>Create either a text cell or choose a file to start working on it.</p>*/}
        <TypeContainer>
          {/*} <li>
            <Categories32 />
            Library
    </li>*/}
          <li onClick={() => setCellType(1)}>
            <DocumentAdd32 /> File
          </li>
          {/*<li>
            <TextColor32 />
            TEXT
          </li>*/}
        </TypeContainer>
      </div>
    )
  }

  if (cellType === 1) {
    return <DataAddFileView />
  }

  return (
    <div>
      <ul>
        <li>Library</li>
        <li>File</li>
        <li>TEXT</li>
      </ul>
    </div>
  )
}

export default SelectCellType

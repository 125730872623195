import React, { useState, FunctionComponent, useContext } from 'react'
import { ChartLineData32 } from '@carbon/icons-react'
import ChartChooseChartView from './chartChooseChartView'
import ChartGraphView from './chartGraphView'
import ChartSetOptionsView from './chartSetOptionsView'
import Loading from '../../loading'
import { useDfContext } from '../../../context/dataframe'
import styled from 'styled-components'
import { ChartType } from '../../../types/types'
import ColumnInfoView from './columnInfoView'
import Table from '../data-view/table'

const ChartContainerStyle = styled.div<{ width?: string; white?: boolean }>`
  width: ${(props) => (props.width ? props.width : '50%')};
  background-color: ${(props) => (props.white ? 'white' : '#b9f4bc')};
  flex-shrink: 0;
`

/**
 * Routes to the correct Chart
 */
const ChartContainer: FunctionComponent<any> = ({ setChartConfig, table }) => {
  const [view, setView] = useState(0)
  const [chartType, setChartType] = useState<ChartType>({
    type: 'line',
    icon: <ChartLineData32 />,
  })
  const DFContext = useDfContext()
  const dataframe = DFContext.dataframe

  if (!dataframe) {
    return <ChartContainerStyle width='10%'></ChartContainerStyle>
  }

  if (DFContext.mode === 'column') {
    return (
      <ChartContainerStyle width='30%'>
        <ColumnInfoView />
      </ChartContainerStyle>
    )
  }

  if (dataframe && view === 0) {
    return (
      <ChartContainerStyle width='30%'>
        <ChartChooseChartView setView={setView} setChartType={setChartType} />
      </ChartContainerStyle>
    )
  }
  if (view === 1) {
    return (
      <ChartContainerStyle width='30%'>
        <ChartSetOptionsView
          setView={setView}
          dataframe={dataframe}
          setChartConfig={setChartConfig}
          chartType={chartType}
        />
      </ChartContainerStyle>
    )
  }
  if (view === 3) {
    //const chartData = echartsDataPreparer(chartConfig)
    return (
      <ChartContainerStyle width='90%' white>
        {table ? <Table /> : <ChartGraphView chart={chartType} />}
      </ChartContainerStyle>
    )
  }

  return (
    <ChartContainerStyle>
      <Loading />
    </ChartContainerStyle>
  )
}

export default ChartContainer
